<template>
  <v-container class="page">
    <div class="page-header mb-5">
      <div></div>
    </div>
    <div class="page-content">
      <div>Hamochi</div>
      <div class="text-h4 mt-2">The Dream Maker</div>
      <div class="text-h4 mt-2">梦想实现家</div>
      <div class="mt-2">By Lim Shi Jian</div>
      <v-img
        :src="require('@/mockups/ABC/book-1/assets/images/0 1.png')"
        max-width="250"
      >
      </v-img>
    </div>
    <div class="page-footer">
      <div class="buttons">
        <!-- <v-btn
          @click="goNext()"
          icon
          class="mx-8"
          color="blue darken-2"
          disabled
        >
          <v-icon x-large>mdi-menu-left</v-icon>
        </v-btn> -->
        <v-btn @click="goNext()" icon class="mx-1" color="blue darken-2" x-large>
          <v-icon x-large>mdi-book-open-variant-outline</v-icon>
        </v-btn>
        <!-- <v-btn @click="goNext()" icon class="mx-8" color="blue darken-2">
          <v-icon x-large>mdi-menu-right</v-icon>
        </v-btn> -->
      </div>
      <div class="mt-2"></div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    goNext() {
      this.$router.push({
        name: "ABCPage2",
      });
    },
  },
};
</script>

<style scoped>
.page-content,
.page-footer,
.page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: #000 solid 1px;
  background: rgb(255, 255, 255) !important;
}

.buttons {
  display: flex;
}

.btn-dis {
  opacity: 0.5;
}
</style>